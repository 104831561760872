import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setUpdatedFavoriteCount } from "reducers/Agency";
import WannabePracticeVideoComponent from "./WannabePracticeVideo.component";
import * as AgencyApi from "api/student/Student.api";
import * as ProfileApi from "api/agency/Profile.api";
import * as DataApi from "api/common/Data.api";
import * as MessageApi from "api/message/Message.api";
import * as StudentApi from "api/student/Student.api";
import { useDispatch } from "react-redux";

const WannabePracticeVideoContainer = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const [studentInfo, setStudentInfo] = useState<any>();
    const [practiceList, setPracticeList] = useState([]);
    const [isPracticeLoading, setIsPracticeLoading] = useState(true);
    const limit = 8;
    const [page, setPage] = useState(location.state?.page || +searchParams.get("page") || 1);
    const [totalCount, setTotalcount] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);

    const [messageRequest, setMessageRequest] = useState<any>({});
    const messageBtnRef = useRef<any>();

    const [isLoading, setIsLoading] = useState(true);

    // favorite
    const btnRef = useRef<any>();
    const [isFavorited, setIsFavorited] = useState(false);

    const [isPublic, setIsPublic] = useState(null);

    const getInfo = async () => {
        const result = await AgencyApi.getInfoForAgency(parseInt(id ? id : "0"));

        if (result?.info?.id) {
            setStudentInfo(result.info);
            getUpdatedFavoriteCount();
            setIsFavorited(result.info.isFavorited);
        } else {
            setIsPublic(result.isActive);
        }
    };

    const getUpdatedFavoriteCount = async () => {
        const result = await StudentApi.getUpdatedFavoriteCount();
        if (result) {
            dispatch(setUpdatedFavoriteCount(result.count));
        }
    };

    const getPractice = async () => {
        setIsPracticeLoading(true);
        const result = await ProfileApi.getListPractice({
            userStudentId: id,
            page,
            limit,
        });

        if (result?.list) {
            const updatedList = await Promise.all(
                result.list.map(async (video: any) => {
                    const videoInfo = await DataApi.getVideoPresignedUrl(video.videoId);
                    if (videoInfo) {
                        return {
                            ...video,
                            url: videoInfo.result,
                        };
                    }
                    return video;
                })
            );

            setPracticeList(updatedList);
            setTotalcount(result.totalCount);
        }
        setIsPracticeLoading(false);
    };

    const captureThumbnail = (videoUrl: string, idx: number) => {
        const videoElement = document.createElement("video");
        videoElement.src = videoUrl;
        videoElement.crossOrigin = "anonymous";
        videoElement.muted = true;
        videoElement.playsInline = true;

        videoElement.addEventListener("loadeddata", () => {
            videoElement.currentTime = 0;
        });

        videoElement.addEventListener("seeked", () => {
            const canvas = document.getElementById(`canvas-${idx}`) as HTMLCanvasElement;

            if (canvas) {
                const context = canvas.getContext("2d");

                if (context) {
                    const videoWidth = videoElement.videoWidth;
                    const videoHeight = videoElement.videoHeight;

                    const canvasWidth = canvas.width;
                    const canvasHeight = canvas.height;

                    // 동영상 비율 및 캔버스 비율 계산
                    const videoAspect = videoWidth / videoHeight;
                    const canvasAspect = canvasWidth / canvasHeight;

                    let sx = 0,
                        sy = 0,
                        sw = videoWidth,
                        sh = videoHeight;

                    // 비율에 맞게 잘라내기 (crop)
                    if (videoAspect > canvasAspect) {
                        // 동영상이 더 넓을 경우, 양쪽을 자른다
                        sw = videoHeight * canvasAspect;
                        sx = (videoWidth - sw) / 2;
                    } else {
                        // 동영상이 더 높을 경우, 위아래를 자른다
                        sh = videoWidth / canvasAspect;
                        sy = (videoHeight - sh) / 2;
                    }

                    // 캔버스에 crop한 동영상 프레임 그리기
                    context.clearRect(0, 0, canvasWidth, canvasHeight);
                    context.drawImage(videoElement, sx, sy, sw, sh, 0, 0, canvasWidth, canvasHeight);

                    const imgElement = document.getElementById(`thumbnail-${idx}`) as HTMLImageElement;
                    imgElement.src = canvas.toDataURL("image/png");
                    imgElement.style.visibility = "visible";
                }
            }
        });

        videoElement.load();
    };

    const changePracticeFavoriteStatus = async (item: any) => {
        if (isProcessing) {
            return;
        }

        setIsProcessing(true);

        const params = { profilePracticeVideoId: item.id };
        const result = item.isFavorited ? await ProfileApi.deletePracticeFavorite(params) : await ProfileApi.createPracticeFavorite(params);

        if (result) {
            const practiceItem = practiceList.find((v: any) => v.id === item.id);

            practiceItem.isFavorited = !item.isFavorited;

            setPracticeList([...practiceList]);
        }

        setIsProcessing(false);
    };

    // message
    const getMessageRequest = async () => {
        const requestInfo = await MessageApi.getRequestMessage(+id);
        console.log(requestInfo);
        if (requestInfo?.info) {
            setMessageRequest(requestInfo.info);
        }

        setIsLoading(false);
    };

    const cancelRequest = async () => {
        messageBtnRef.current.disabled = true;
        if (messageRequest) {
            const result = await MessageApi.updateMessageInfo(messageRequest?.id, {
                messageType: "messageType:cancel",
            });
            if (result) {
                await getMessageRequest();
            }
        }
        messageBtnRef.current.disabled = false;
    };

    const createRequest = async () => {
        messageBtnRef.current.disabled = true;
        const result = await MessageApi.createRequest({ studentId: id });
        if (result) {
            await getMessageRequest();
        }
        messageBtnRef.current.disabled = false;
    };

    const changeFavoriteStatus = async () => {
        btnRef.current.disabled = true;

        const params = { studentId: studentInfo.id };
        const result = isFavorited ? await StudentApi.deleteFavorite(params) : await StudentApi.createFavorite(params);

        if (result) {
            isFavorited ? setIsFavorited(false) : setIsFavorited(true);
            btnRef.current.disabled = false;
        } else {
            btnRef.current.disabled = true;
        }
    };

    const init = async () => {
        if (id) {
            await getInfo();
            await getPractice();
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        if (practiceList) {
            practiceList.forEach((item: any, idx: number) => {
                captureThumbnail(item.url, idx);
            });
        }
    }, [practiceList]);

    useEffect(() => {
        init();
    }, [page]);

    useEffect(() => {
        //if (studentInfo?.userSchoolId) {
        getMessageRequest();
        // }
      }, [studentInfo?.id]);

    return (
        <WannabePracticeVideoComponent
            studentInfo={studentInfo}
            practiceList={practiceList}
            isPracticeLoading={isPracticeLoading}
            changePracticeFavoriteStatus={changePracticeFavoriteStatus}
            // pagination
            limit={limit}
            page={page}
            setPage={setPage}
            totalCount={totalCount}
            // message
            messageRequest={messageRequest}
            createRequest={createRequest}
            cancelRequest={cancelRequest}
            messageBtnRef={messageBtnRef}
            // favorite
            btnRef={btnRef}
            isFavorited={isFavorited}
            changeFavoriteStatus={changeFavoriteStatus}
            isPublic={isPublic}
        />
    );
};

export default WannabePracticeVideoContainer;
