// axios 인터 셉터
import axios from "axios";
import {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  removeAuthTokens,
} from "../helpers/Auth.helper";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const init = async () => {
  console.log("Axios.interceptor::init()");
  axios.interceptors.request.use(
    (config) => {
      console.log("Axios.interceptor::request");
      const accessToken = getAccessToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      config.headers["accesskey"] = process.env.REACT_APP_API_ACCESS_KEY;
      config.headers["secretkey"] = process.env.REACT_APP_API_SECRET_KEY;
      config.headers["language-code"] = navigator.language;
      config.headers["X-Requested-With"] = "XMLHttpRequest";
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["Access-Control-Allow-Methods"] =
        "GET, PUT, POST, DELETE, OPTIONS";
      config.headers["Cache-Control"] = "no-cache";
      config.headers["Pragma"] = "no-cache";

      return config;
    },
    (err) => {
      console.error(err);
      Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const response = err.response;
      return new Promise((resolve, reject) => {
        // Do refresh Token
        if (
          response.status === 401 &&
          response.data.message === "EXPIRED_ACCESS_TOKEN"
        ) {
          console.log("do refresh token");
          let config = response.config;
          let headers = config.headers;
          let refreshTokenParams = null;

          headers["Authorization"] = `Bearer ${getAccessToken()}`;
          refreshTokenParams = {
            refreshToken: getRefreshToken(),
          };

          const refreshRequest = axios.create({ headers });
          return refreshRequest
            .post("/auth/token", refreshTokenParams)
            .then(({ status, data }) => {
              if (status === 200) {
                console.log("get new token");
                setAccessToken(data.data.jwtToken);
                setRefreshToken(
                  data.data.refreshToken,
                  data.data.expiresAtRefresh
                );

                headers["Authorization"] = `Bearer ${data.data.jwtToken}`;

                const retryAxios = axios.create({ headers });

                if (config.method === "get") {
                  return retryAxios.get(config.url);
                } else if (config.method === "post") {
                  return retryAxios.post(config.url, config.data);
                } else if (config.method === "patch") {
                  return retryAxios.patch(config.url, config.data);
                } else if (config.method === "put") {
                  return retryAxios.put(config.url, config.data);
                } else if (config.method === "delete") {
                  return retryAxios.delete(config.url, { data: config.data });
                }
              } else {
                resolve(status);
                //throw new Error('알 수 없는 에러가 발생했습니다.');
              }
            })
            .then((result) => {
              console.log("result", result);
              resolve(result);
            })
            .catch((_) => {
              console.log("axios response interceptor error!!!");
              resolve(response);
            });
        } else if (
          response.status === 401 &&
          response.data.message === "EXPIRED_REFRESH_TOKEN"
        ) {
          removeAuthTokens();
          resolve(response);
        } else if (
          response.status === 403 &&
          response.data.message === "INVALID_ACCESS_TOKEN"
        ) {
          removeAuthTokens();
          resolve(response);
        } else if (response.status === 400) {
          if (response.data.errorCode) {
            alert(response.data.message);
          }
          resolve(response);
        } else if (
          response.status === 403 &&
          response.data.message === "INVALID_REFRESH_TOKEN"
        ) {
          removeAuthTokens();
          resolve(response);
        } else {
          console.log(err);
          console.log(response);
          resolve(response);
        }
      });
    }
  );
};
