import { BAD_WORDS } from "data/badWords.data";
import { getCurrentLanguage } from "locales/i18n";

// 세자리 마다 comma를 붙인 숫자 리턴
export const getNumberWithComma = (value: number | string) => {
    if (!value) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getCurrentLangName = (value: any) => {
    const currenetLang = getCurrentLanguage();

    return value[currenetLang] ? value[currenetLang] : value["en"];
};

export const getEnName = (value: any) => (value["en"] ? value["en"] : "");

export const getOnlyNumberValue = (value: string) => value.replace(/[^0-9]/g, "");

export const isBadWord = (value: string) => {
    if (!value) {
        return false;
    }

    return BAD_WORDS.some((word: string) => value.toLowerCase().includes(word.toLowerCase()))

};
