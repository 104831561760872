import LoadingComponent from "commons/loading/Loading.component";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PracticeNewComponent = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, practiceParams, onChangeInput, uploadVideo, videoRef, btnRef, onClickBtnSave, onClickBtnUpdate, isOnNetworking, isVideoLoading } = props;

    return (
        <main className="">
            <div className="profile-training-video-new">
                <div className="con">
                    <div className="title">
                        {/* <!-- 선택한 title에 class active 추가 --> */}
                        <button
                            className=""
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "profile", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}
                        </button>
                        <button
                            className="active"
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "practice", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtTitle")}
                        </button>
                    </div>
                    <div
                        className="back-btn"
                        onClick={() => {
                            if (id) {
                                navigate(`/student/profile/practice/${id}`, {
                                    state: {
                                        tab: location?.state?.tab || "practice",
                                        profileSubTab: location?.state?.profileSubTab || "main",
                                        page: location?.state?.page || 1,
                                    },
                                });
                            } else {
                                navigate("/student/profile", {
                                    state: {
                                        tab: location?.state?.tab || "practice",
                                        profileSubTab: location?.state?.profileSubTab || "main",
                                        page: location?.state?.page || 1,
                                    },
                                });
                            }
                        }}></div>
                    <div className="wrap">
                        <div className="title">
                            {id ? (
                                <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtEdit")}</h2>
                            ) : (
                                <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtRegister")}</h2>
                            )}
                        </div>
                        <div className="preview">
                            <div className="preview-wrap">
                                <div className="info">
                                    <div className="input-box">
                                        <h3>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtTitle")}</h3>
                                        <input
                                            type="text"
                                            placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtPleaseEnterTitle")}
                                            value={practiceParams.title}
                                            onChange={(evt) => onChangeInput("title", evt.target.value)}
                                        />
                                    </div>
                                    <div className="input-box input-file">
                                        <h3>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtPracticeVideoFile")}</h3>
                                        <div className="file-wrap">
                                            <label className="btn" htmlFor="input-file-video">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtSelect")}
                                            </label>
                                            <input
                                                className="upload-file"
                                                type="file"
                                                id="input-file-video"
                                                onChange={(evt) => uploadVideo(evt)}
                                                accept="video/*"
                                            />
                                            <input
                                                className="upload-name"
                                                placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtPleaseSelectFile")}
                                                value={practiceParams.videoName}
                                            />
                                        </div>
                                    </div>
                                    <div className="img">
                                        <h3>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtPreview")}</h3>
                                        {isVideoLoading ? (
                                            <LoadingComponent />
                                        ) : (
                                            <video controls ref={videoRef} muted playsInline controlsList="nodownload" style={{ visibility: "hidden" }}>
                                                <source type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </div>
                                    <div className="input-box">
                                        <h3>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtVideoShootingDate")}</h3>
                                        <input
                                            type="date"
                                            id="currentDate"
                                            value={practiceParams.shootingDate}
                                            onChange={(evt) => onChangeInput("shootingDate", evt.target.value)}
                                        />
                                    </div>
                                    <div className="input-box">
                                        <h3>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtContent")}</h3>
                                        <textarea
                                            placeholder={getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtPleaseEnterContent")}
                                            value={practiceParams.content}
                                            onChange={(evt) => onChangeInput("content", evt.target.value)}></textarea>
                                    </div>
                                    <div className="input-box">
                                        <h3>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.txtVisibility")}</h3>
                                        <select value={practiceParams.visibilityCode} onChange={(evt) => onChangeInput("visibilityCode", evt.target.value)}>
                                            <option value="visibility:public">{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtPublic")}</option>
                                            <option value="visibility:agencyOnly">
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtAgencyOnly")}
                                            </option>
                                            <option value="visibility:private">{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtPrivate")}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn">
                            {id ? (
                                <button
                                    ref={btnRef}
                                    onClick={() => {
                                        onClickBtnUpdate();
                                    }}
                                    disabled={isOnNetworking}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.btnEdit")}
                                </button>
                            ) : (
                                <button
                                    ref={btnRef}
                                    onClick={() => {
                                        onClickBtnSave();
                                    }}
                                    disabled={isOnNetworking}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeNew.btnRegister")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default PracticeNewComponent;
