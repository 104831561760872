import React, { useEffect, useRef, useState } from "react";
import PracticeDetailComponent from "./PracticeDetail.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";
import * as AgencyApi from "api/agency/Agency.api";
import * as ProfileApi from "api/student/Profile.api";
import * as DataApi from "api/common/Data.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const PracticeDetailContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [practiceVideoInfo, setPracticeVideoInfo] = useState(null);
  const detailVideoRef = useRef(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);
  const { id } = useParams();
  //
  const [showPopupType, setShowPopupType] = useState("view");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [profileMediaType, setProfileMediaType] = useState("");
  const [isAgencyPopup, setIsAgencyPopup] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState(null);

  const getPracticeVideoInfo = async () => {
    const result = await ProfileApi.getInfoPractice(+id);

    if (result?.info) {
      if (result.info.videoInfo) {
        const videoInfo = await DataApi.getVideoPresignedUrl(
          result?.info?.videoInfo?.id
        );
        if (videoInfo) {
          result.info.videoInfo.url = videoInfo.result;
        }
      }
      setPracticeVideoInfo(result.info);
      setSelectedVideoUrl(result.info.videoInfo.url);
    } else {
      navigate("/student/profile", {
        state: {
          tab: location?.state?.tab || "practice",
          profileSubTab: location?.state?.profileSubTab || "main",
          page: 1,
        },
      });
    }
  };

  const practiceDetailInit = async () => {
    setIsLoading(true);
    await getPracticeVideoInfo();
    setIsLoading(false);
  };

  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  const handleEnded = () => {
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    const video = detailVideoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
  };

  const handlePlay = () => {
    setInitFlag(false);
  };

  const handlePause = () => {
    const video = detailVideoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
    setInitFlag(true);
  };

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(practiceVideoInfo?.videoInfo?.id);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  useEffect(() => {
    const video = detailVideoRef.current;

    if (video) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
        video.play().catch((e: any) => console.log(e));
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [selectedVideoUrl]);

  const deletePracticeVideo = async () => {
    if (
      window.confirm(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profilePracticeDetail.txtConfirmDelete"
        )
      )
    ) {
      const result = await ProfileApi.deletePracticeVideo(+id);
      if (result) {
        navigate("/student/profile", {
          state: {
            tab: location?.state?.tab || "practice",
            profileSubTab: location?.state?.profileSubTab || "main",
            page: 1,
          },
        });
      }
    }
  };

  const openViewPopup = async (id: number) => {
    if (id) {
      const result = await ProfileApi.getListPraciceViewedAgency({
        id: id,
        actionType: "view",
      });

      if (result.list) {
        setPopupList(result.list);
      }
      setIsShowPopup(true);
      setShowPopupType("view");
    }
  };

  const openFavoritePopup = async (id: number) => {
    if (id) {
      const result = await ProfileApi.getListPraciceViewedAgency({
        id: id,
        actionType: "favorite",
      });

      if (result.list) {
        setPopupList(result.list);
      }
      setIsShowPopup(true);
      setShowPopupType("favorite");
    }
  };

  const openAgencyPopup = async (id: number) => {
    const result = await AgencyApi.getInfo(id);
    if (result.info) {
      setAgencyInfo(result.info);
    }
    setIsAgencyPopup(true);
  };

  const onChangeVisibility = async (value: string) => {
    setPracticeVideoInfo((prevState: any) => ({
      ...prevState,
      visibilityCode: value,
    }));
    await ProfileApi.updatePracticeVideoVisibility(+id, {
      visibilityCode: value,
    });
  };

  useEffect(() => {
    practiceDetailInit();
  }, []);

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <PracticeDetailComponent
      {...props}
      id={id}
      practiceVideoInfo={practiceVideoInfo}
      detailVideoRef={detailVideoRef}
      selectedVideoUrl={selectedVideoUrl}
      deletePracticeVideo={deletePracticeVideo}
      //
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      popupList={popupList}
      openViewPopup={openViewPopup}
      profileMediaType={profileMediaType}
      //
      showPopupType={showPopupType}
      isAgencyPopup={isAgencyPopup}
      setIsAgencyPopup={setIsAgencyPopup}
      agencyInfo={agencyInfo}
      openAgencyPopup={openAgencyPopup}
      openFavoritePopup={openFavoritePopup}
      onChangeVisibility={onChangeVisibility}
    />
  );
};

export default PracticeDetailContainer;
