import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import * as Axios from "api/Base.interceptor";
import TagManager from "react-gtm-module";
import Router from "./Router";
import { getAccessToken, setAccessToken } from "helpers/Auth.helper";
import { getMyInfo, saveAppPushInfo, setPushActivate } from "api/common/Auth.api";
import { setIsLogin, setInfo as setUserInfo } from "reducers/User";
import * as StudentApi from "api/student/Student.api";
import * as AgencyApi from "api/agency/Agency.api";
import * as SchoolApi from "api/school/School.api";
import { isFcmSupported, listenForForegroundMessages, requestNotificationPermission } from "helpers/Firebase.helper";
import { getAppAccessToken, getIsAppInitData, hideSplash, setIsAppInitData } from "helpers/appLocalStorageHelper";
import { getAppVersionInfo } from "api/App.api";
import { setIsApp } from "reducers/Common";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

function App() {
    const [isInit, setIsInit] = useState(false);
    const dispatch = useDispatch();

    const { isApp } = useSelector((state: any) => state.common);

    const initGA = () => {
        if (process.env.REACT_APP_ENV !== "local") {
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? "",
            };
            TagManager.initialize(tagManagerArgs);
        }
    };

    // app update
    const appUpdate = async (isIos: boolean) => {
        const iOSLink = "itms-apps://itunes.apple.com/app/6447291454";
        const androidLink = "https://play.google.com/store/apps/details?id=kr.co.topia.app";
        await (window as any).flutter_inappwebview
            .callHandler(
                "executeExternalLink",
                isIos ? iOSLink : androidLink
            )(window as any)
            .flutter_inappwebview.callHandler("exitApp");
    };

    const init = async () => {
        Axios.init();

        // 앱인 경우
        if ((window as any).flutter_inappwebview) {
            const OSType = await (window as any).flutter_inappwebview.callHandler("getOSType");

            const isIos = OSType === "iOS";

            // db current app version 조회
            const currentAppVersion = await getAppVersionInfo({
                deviceTypeCode: `deviceType:${isIos ? "ios" : "android"}`,
            });

            if (currentAppVersion?.info) {
                // device app version 조회
                const deviceAppVersion = await (window as any).flutter_inappwebview.callHandler("getAppVersion");

                // 앱 업데이트 버전이 있는 경우
                if (currentAppVersion.info.version > deviceAppVersion) {
                    // 강제 업데이트인 경우
                    if (currentAppVersion.info.needForceUpdate) {
                        alert(getLanguageValue(PAGE_TYPE.COMMON, "app.txtForceUpdate"));
                        await appUpdate(isIos);
                    }
                    // 단순 업데이트인 경우
                    else {
                        if (window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "app.txtNeedUpdate"))) {
                            await appUpdate(isIos);
                        }
                    }
                }
            }

            // app local storage access token 확인
            const accessToken = await getAppAccessToken();
            if (accessToken) {
                setAccessToken(accessToken);
            }

            // 앱 여부 redux 저장
            dispatch(setIsApp(true));
        }

        // cookie의 access token 체크
        const accessToken = getAccessToken();
        if (accessToken) {
            const myInfoResult = await getMyInfo();

            if (myInfoResult?.info) {
                if (myInfoResult.info.accountTypeCode === "accountType:student") {
                    const result = await StudentApi.getInfoForStudent();
                    if (result) {
                        myInfoResult.info.profileImage = result.info.profileImageInfo ? result.info.profileImageInfo.url : null;
                    } else {
                        myInfoResult.info.profileImage = null;
                    }
                }
                if (myInfoResult.info.accountTypeCode === "accountType:agency") {
                    const result = await AgencyApi.getInfo(myInfoResult.info.userAgencyId);
                    if (result) {
                        myInfoResult.info.logoImageInfo = result.info.logoImageInfo ? result.info.logoImageInfo : null;
                        myInfoResult.info.agencyName = result.info.agencyName;
                    } else {
                        myInfoResult.info.logoImageInfo = null;
                    }
                }

                if (myInfoResult.info.accountTypeCode === "accountType:school") {
                    const result = await SchoolApi.getInfo(myInfoResult.info.userSchoolId);
                    if (result) {
                        myInfoResult.info.logoImageInfo = result.schoolInfo.logoImageInfo ? result.schoolInfo.logoImageInfo : null;
                    } else {
                        myInfoResult.info.logoImageInfo = null;
                    }
                }
                const myInfo = myInfoResult.info;
                dispatch(setIsLogin(true));
                dispatch(setUserInfo(myInfo));

                if (!isApp) {
                    await requestNotificationPermission();
                }
            }
            //await listenForForegroundMessages();
        }

        initGA();

        if (isFcmSupported()) {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.addEventListener("message", (event) => {
                    if (event.data && event.data.type === "RELOAD_PAGE") {
                        window.location.reload();
                    }
                });
            }
        }

        if ((window as any).flutter_inappwebview) {
            // app splash hide
            await hideSplash();
        }

        setIsInit(true);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <CookiesProvider>
            {isInit ? (
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            ) : null}
        </CookiesProvider>
    );
}

export default App;
