import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedFavoriteCount } from "reducers/Agency";

import { getUserType } from "helpers/Common.helper";
import { PAGE_TYPE } from "enums";

import * as StudentApi from "api/student/Student.api";
import LayoutComponent from "./Layout.component";
import { saveLogInfo } from "api/Log.api";

const LayoutContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isPrivate, isSignOut, pageType } = props;
  const { isLogin, info: userInfo } = useSelector((state: any) => state.user);

  const getContentWrapClassName = () => {
    if (pageType === PAGE_TYPE.COMMON) {
      return "landing";
    } else if (pageType === PAGE_TYPE.AGENCY) {
      return "agency";
    } else if (pageType === PAGE_TYPE.SCHOOL) {
      return "school";
    } else if (pageType === PAGE_TYPE.STUDENT) {
      return "student";
    }
  };

  const getUpdatedFavoriteCount = async () => {
    const result = await StudentApi.getUpdatedFavoriteCount();
    if (result) {
      dispatch(setUpdatedFavoriteCount(result.count));
    }
  };

  useEffect(() => {
    if (pageType === PAGE_TYPE.AGENCY) {
      getUpdatedFavoriteCount();
    }

    const sendLogToAPI = async (path: string) => {
        await saveLogInfo({ uri: path });
    };

    // 경로가 변경될 때마다 API 호출
    sendLogToAPI(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // 로그인 하지 않은 상태에서 private 페이지 접근시 로그인 페이지로 redirect
    if (!isLogin && isPrivate) {
      navigate("/login", {
        replace: true,
        state: { nextUrl: location.pathname },
      });
    } else if (isLogin) {
      const userAccountType = getUserType(userInfo?.accountTypeCode);
      // 로그인한 상태에서 회원가입/로그인 페이지등 접근했을 때
      // private 페이지 type이 user account type과 맞지 않은 경우 해당 user type의 홈페이지로 redirect
      if (isSignOut || (isPrivate && pageType !== userAccountType)) {
        navigate(`/${userAccountType}`, { replace: true });
      }
    }

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <LayoutComponent
      {...props}
      getContentWrapClassName={getContentWrapClassName}
    />
  );
};

export default LayoutContainer;
