import { getFormattedDateValue } from "helpers/Datetime.helper";
import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const WannabePracticeVideoInfoComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        studentInfo,
        practiceVideoInfo,
        detailVideoRef,
        isLoading,
        practiceBtnRef,
        changePracticeFavoriteStatus,
        // message
        messageRequest,
        createRequest,
        cancelRequest,
        messageBtnRef,
        // favorite
        btnRef,
        isFavorited,
        changeFavoriteStatus,
        isPublic,
    } = props;

    return (
        <main className="">
            {isLoading ? (
                <LoadingComponent />
            ) : isPublic !== null && !isPublic ? (
                <div className="private-profile">
                    <img src="./imgs/icons/lock-icon.png" alt="lock" />
                    <p>{getLanguageValue(PAGE_TYPE.AGENCY, "studentInfo.txtPrivateProfile")}</p>
                </div>
            ) : (
                <>
                    <div className="area">
                        <div className="title">
                            {/* <!-- 선택한 title에 class active 추가 --> */}
                            <button onClick={(evt) => navigate(`/agency/wannabe/info/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtTitle")}
                            </button>
                            <button className="active" onClick={(evt) => navigate(`/agency/wannabe/practice-video/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabePracticeVideo.txtTitle")}
                            </button>
                            <button onClick={(evt) => navigate(`/agency/wannabe/profile/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtTitle")}
                            </button>
                            {studentInfo?.userSchoolId ? (
                                <button onClick={(evt) => navigate(`/agency/wannabe/school-evaluation/${studentInfo?.id}`)}>
                                    {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtTitle")}
                                </button>
                            ) : null}
                        </div>
                        <div className="back-btn" onClick={(evt) => navigate(-1)}></div>
                    </div>
                    <div className="student-training-video-info">
                        <div className="con">
                            <div className="wrap">
                                <div className="video-title">
                                    <h2>{practiceVideoInfo?.title}</h2>
                                    <p className="date">{getFormattedDateValue(practiceVideoInfo?.shootingDate, "YYYY.MM.DD")}</p>
                                </div>
                                <div className="toggle-btn">
                                    <button ref={practiceBtnRef} onClick={(evt) => changePracticeFavoriteStatus()}>
                                        {practiceVideoInfo?.isFavorited ? (
                                            <img src="./imgs/icons/btn-like-on.png" alt="like toggle button" />
                                        ) : (
                                            <img src="./imgs/icons/btn-like.png" alt="like toggle button" />
                                        )}
                                    </button>
                                </div>
                                <div className="preview">
                                    <div className="preview-wrap">
                                        <div className="img">
                                            <div className="play-img">
                                                <video controls ref={detailVideoRef} muted playsInline controlsList="nodownload">
                                                    <source type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <p>
                                        {practiceVideoInfo
                                            ? practiceVideoInfo.content.split("\n").map((line: any, index: any) => (
                                                  <React.Fragment key={index}>
                                                      {line}
                                                      <br />
                                                  </React.Fragment>
                                              ))
                                            : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 메세지 버튼 */}
                    <div className="floating-bar">
                        <div className="wrap">
                            <div className="chat-request-btn">
                                {messageRequest ? (
                                    messageRequest.messageStatusCode === "messageType:accept" ? (
                                        <button
                                            ref={messageBtnRef}
                                            onClick={() => {
                                                messageBtnRef.current.disabled = true;
                                                navigate(`/agency/message/${messageRequest.channelId}`, {
                                                    state: {
                                                        schoolName: studentInfo.schoolInfo?.name,
                                                    },
                                                });
                                                messageBtnRef.current.disabled = false;
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnGoToMessagePage")}
                                        </button>
                                    ) : messageRequest.messageStatusCode === "messageType:request" ? (
                                        <button
                                            ref={messageBtnRef}
                                            onClick={(e) => {
                                                cancelRequest();
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnCancelRequest")}
                                        </button>
                                    ) : (
                                        <button
                                            ref={messageBtnRef}
                                            onClick={(e) => {
                                                createRequest();
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                        </button>
                                    )
                                ) : (
                                    <button
                                        ref={messageBtnRef}
                                        onClick={(e) => {
                                            createRequest();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                    </button>
                                )}
                            </div>

                            <div className="btn-con">
                                <button
                                    className={isFavorited ? "favorite-btn" : "favorite-disabled-btn"}
                                    ref={btnRef}
                                    onClick={(evt) => changeFavoriteStatus()}>
                                    <img src={isFavorited ? "./imgs/student/home/favorite-btn-2.png" : "./imgs/student/home/favorite-btn-1.png"} />
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </main>
    );
};

export default WannabePracticeVideoInfoComponent;
