import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PracticeDetailComponent = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        id,
        practiceVideoInfo,
        detailVideoRef,
        deletePracticeVideo,
        //
        isShowPopup,
        setIsShowPopup,
        popupList,
        openViewPopup,
        showPopupType,
        profileMediaType,
        isAgencyPopup,
        setIsAgencyPopup,
        agencyInfo,
        openAgencyPopup,
        openFavoritePopup,
        onChangeVisibility,
    } = props;

    return (
        <main className="">
            <div className="profile-training-video-info">
                <div className="con">
                    <div className="title">
                        {/* <!-- 선택한 title에 class active 추가 --> */}
                        <button
                            className=""
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "profile", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}
                        </button>
                        <button
                            className="active"
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "practice", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtTitle")}
                        </button>
                    </div>
                    <div
                        className="back-btn"
                        onClick={() => {
                            navigate("/student/profile", {
                                state: {
                                    tab: location?.state?.tab || "practice",
                                    profileSubTab: location?.state?.profileSubTab || "main",
                                    page: location?.state?.page || 1,
                                },
                            });
                        }}></div>
                    <div className="wrap">
                        <div className="video-title">
                            <h2>{practiceVideoInfo.title}</h2>
                            <p className="date">{getFormattedDateValue(practiceVideoInfo.shootingDate, "YYYY.MM.DD")}</p>
                        </div>
                        <div className="preview">
                            <div className="preview-wrap">
                                <div className="img">
                                    <div className="play-img">
                                        <video controls ref={detailVideoRef} muted playsInline controlsList="nodownload">
                                            <source type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text">
                            <p>
                                {practiceVideoInfo.content.split("\n").map((line: any, index: any) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>
                        <div className="select-list">
                            <select
                                value={practiceVideoInfo.visibilityCode}
                                onChange={(e) => {
                                    onChangeVisibility(e.target.value);
                                }}>
                                <option value="visibility:public">{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtPublic")}</option>
                                <option value="visibility:agencyOnly">{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtAgencyOnly")}</option>
                                <option value="visibility:private">{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtPrivate")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="floating-bar">
                        <div className="wrap">
                            <div className="chat-request-btn">
                                <div className="delete-btn">
                                    <button
                                        onClick={() => {
                                            deletePracticeVideo();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeDetail.btnDelete")}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            navigate(`/student/profile/practice/${id}/edit`, {
                                                state: {
                                                    tab: location?.state?.tab || "practice",
                                                    profileSubTab: location?.state?.profileSubTab || "main",
                                                    page: location?.state?.page || 1,
                                                },
                                            });
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profilePracticeDetail.btnEdit")}
                                    </button>
                                </div>
                            </div>

                            <div className="btn-con">
                                <button
                                    className="favorite"
                                    onClick={(e) => {
                                        openFavoritePopup(practiceVideoInfo?.id);
                                    }}>
                                    <img src="./imgs/icons/like-3.png" alt="number of likes" />
                                    <span>{practiceVideoInfo.favoriteCnt}</span>
                                </button>
                                <button
                                    className="view"
                                    onClick={(e) => {
                                        openViewPopup(practiceVideoInfo?.id);
                                    }}>
                                    <img src="./imgs/icons/view-black.png" alt="view count icon" />
                                    <span>{practiceVideoInfo.viewCnt}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {isShowPopup ? (
                    <div className="popup popup-view-count">
                        <div className="con">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsShowPopup(false);
                                }}></div>
                            <div className="wrap">
                                <div className="title">
                                    <p className="ellipsis">
                                        <span>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtVideo")}</span>{" "}
                                        {showPopupType === "view"
                                            ? getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtViewedAgency")
                                            : getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtFavoritedAgency")}
                                    </p>
                                </div>
                                {popupList && popupList.length > 0 ? (
                                    <div className="list">
                                        <ul>
                                            {popupList.map((item: any, index: number) => (
                                                <li
                                                    onClick={(e) => {
                                                        openAgencyPopup(item.id);
                                                    }}>
                                                    <div className="item">
                                                        <div
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    item && item.logoImageInfo
                                                                        ? item.logoImageInfo.url.replace(/ /g, "%20")
                                                                        : "./imgs/icons/agency-logo-default.png"
                                                                })`,
                                                            }}></div>
                                                        <div
                                                            className="view-count"
                                                            style={{
                                                                display: showPopupType === "view" ? "block" : "none",
                                                            }}>
                                                            <button>
                                                                <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                                {item.viewCnt}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="agency-name">
                                                        <p className="ellipsis">{item.companyName}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {isAgencyPopup && agencyInfo ? (
                <div className="popup popup-casting-manager-info">
                    <div className="con">
                        <div className="wrap">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsAgencyPopup(false);
                                }}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtCastingManagerInfo")}</h2>
                            <div className="agency-info">
                                <img
                                    src={agencyInfo.logoImageInfo ? agencyInfo.logoImageInfo.url.replace(/ /g, "%20") : "./imgs/icons/agency-logo-default.png"}
                                    alt="agency-logo"
                                />
                                <p>{agencyInfo.companyName}</p>
                                <div className="casting-manager-info">
                                    <div className="business-card"></div>
                                    <div className="name">
                                        {agencyInfo.name}
                                        {/* <!-- 이메일 인증 했을경우 뱃지 --> */}
                                        {agencyInfo.hasVerifiedEmail === 1 ? <img src="./imgs/icons/badge.png" alt="badge" /> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="btn">
                                <button
                                    onClick={(e) => {
                                        setIsAgencyPopup(false);
                                    }}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtClose")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};
export default PracticeDetailComponent;
