import React from "react";
import { getCurrentLanguage, getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { formatTimeAgo } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import PostFormPopupComponent from "./PostFormPopup.component";
import CommentFormPopupComponent from "./CommentFormPopup.component";
import LikePopupPopupComponent from "./LikePopup.component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeComponent = (props: any) => {
    const {
        isLoading,
        isOnNetworking,
        isUnder14,
        parentEmail,
        postList,
        setPostList,
        createComment,
        selectedPostId,
        setSelectedPostId,
        isShowFormPopup,
        setIsShowFormPopup,
        isShowLikePopup,
        setIsShowLikePopup,
        deletePostValue,
        selectedCommentId,
        setSelectedCommentId,
        setIsShowCommentPopup,
        setSelectedCommentValue,
        deletePostCommentValue,
        setTag,
        lastItemRef,
        createPostLikeValue,
        deletePostLikeValue,
        recWannabeList,
        translateComment,
        createPostReportValue,
        createPostCommentReportValue,
        commentSectionRefs,
        isShowPostListLoading,
        postSectionRef,
    } = props;

    const settings = {
        className: "slider",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };

    const currentLanguageCode = getCurrentLanguage();

    interface HashtagTextProps {
        text: string;
    }

    const HashtagText: React.FC<HashtagTextProps> = ({ text }) => {
        // 정규 표현식으로 해시태그 찾기
        const hashtagPattern = /(#\p{L}[\p{L}\p{N}_]*)/gu;

        // 해시태그를 특정 태그로 감싸기
        const formattedText = text.split(hashtagPattern).map((part, index) => {
            if (hashtagPattern.test(part)) {
                return (
                    <a
                        key={`hash-${index}`}
                        className="v2-btn-hashtag"
                        onClick={(e) => {
                            e.preventDefault();
                            setTag(part.replace("#", ""));
                        }}>
                        {part}
                    </a>
                );
            }
            return <React.Fragment key={`hash-${index}`}>{part}</React.Fragment>;
        });

        return <>{formattedText}</>;
    };

    return (
        <>
            <main className="">
                {isLoading ? (
                    <LoadingComponent />
                ) : isUnder14 ? (
                    <div className="v2-sticky-panel v2-sticky-panel-warn">
                        <p>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "home.txtNeedParentConfirm1")} 😥
                            <br />
                            <strong>{parentEmail}</strong> {getLanguageValue(PAGE_TYPE.STUDENT, "home.txtNeedParentConfirm2")}
                        </p>
                    </div>
                ) : (
                    <>
                        <section className="v2-recommended-wannabe-list">
                            <LikePopupPopupComponent
                                isShowLikePopup={isShowFormPopup}
                                setIsShowLikePopup={setIsShowLikePopup}
                                postId={selectedPostId}
                                {...props}
                            />
                            <PostFormPopupComponent
                                postId={selectedPostId}
                                isShowFormPopup={isShowFormPopup}
                                setIsShowFormPopup={setIsShowFormPopup}
                                {...props}
                            />
                            <CommentFormPopupComponent {...props} />
                            <div className="con">
                                <div className="title">
                                    <h6>
                                        <strong>추천</strong>
                                        지망생
                                    </h6>
                                </div>

                                <div className="list">
                                    <ul>
                                        {recWannabeList
                                            ? recWannabeList.map((item: any, index: number) => (
                                                  <li key={`rec-wa-${index}`}>
                                                      <div className="item">
                                                          <div className="v2-wannabe-thumbnail hover">
                                                              <img src={item.imageUrl} alt={item.name} />
                                                          </div>
                                                          <div className="name">
                                                              <p className="ellipsis">{item.name}</p>
                                                          </div>
                                                      </div>
                                                  </li>
                                              ))
                                            : null}
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <section className="v2-timeline-posting-list" ref={postSectionRef}>
                            <div className="con">
                                <div className="list">
                                    {isShowPostListLoading ? (
                                        <LoadingComponent />
                                    ) : (
                                        <ul>
                                            {postList.map((item: any, index: number) => (
                                                <li ref={postList.length - 1 === index ? lastItemRef : null} key={`post-${index}`}>
                                                    <div className="item">
                                                        <div className="v2-timeline-posting">
                                                            <div className="con">
                                                                <div className="body">
                                                                    <div className="col">
                                                                        {/* <!-- Image 영역 --> */}
                                                                        <div className="img-list">
                                                                            <Slider {...settings}>
                                                                                {item.imageList.map((image: any, idx: number) => (
                                                                                    <React.Fragment key={`image-${index}-${idx}`}>
                                                                                        <div
                                                                                            className="img"
                                                                                            style={{
                                                                                                backgroundImage: `url("${image.url})`,
                                                                                            }}></div>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </Slider>
                                                                            <div className="ui">
                                                                                <div className="v2-btn-slide v2-btn-slide-prev">
                                                                                    <img src="./imgs/common/btn-slide-prev.png" />
                                                                                </div>
                                                                                <div className="v2-btn-slide v2-btn-slide-next">
                                                                                    <img src="./imgs/common/btn-slide-next.png" />
                                                                                </div>

                                                                                <div className="v2-slide-indicator">
                                                                                    <ul>
                                                                                        <li>
                                                                                            <div className="item"></div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="item"></div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="item"></div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="item selected"></div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="item"></div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="item"></div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="item"></div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="posting">
                                                                            <div className="con">
                                                                                <div className="header">
                                                                                    <div className="profile">
                                                                                        <div className="con">
                                                                                            <div className="img-wrap">
                                                                                                <div className="v2-wannabe-thumbnail">
                                                                                                    <img src={item.imageUrl} alt={item.name} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="info-wrap">
                                                                                                <div className="name">
                                                                                                    <p>{item.name}</p>
                                                                                                </div>
                                                                                                <div className="date">
                                                                                                    <p>{formatTimeAgo(item.createdAt)}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={`v2-tooltip-t1 ${item.isShowToolTip ? "on" : ""}`}>
                                                                                                <div
                                                                                                    className="btn-tooltip"
                                                                                                    onClick={(e: any) => {
                                                                                                        const newList = [...postList];
                                                                                                        const post = newList.find((v: any) => v.id === item.id);

                                                                                                        post.isShowToolTip = post.isShowToolTip ? false : true;

                                                                                                        if (post.isShowToolTip) {
                                                                                                            newList.map((v: any) => {
                                                                                                                if (v.id !== item.id) {
                                                                                                                    v.isShowToolTip = false;
                                                                                                                }
                                                                                                                for (const commnet of v.commentList) {
                                                                                                                    commnet.isShowToolTip = false;
                                                                                                                }
                                                                                                            });
                                                                                                        }

                                                                                                        setPostList(newList);
                                                                                                    }}>
                                                                                                    <img src="./imgs/common/more-t1.png" alt="More Button" />
                                                                                                </div>

                                                                                                <div className="tooltip-list">
                                                                                                    <ul>
                                                                                                        {item.isOwner ? (
                                                                                                            <>
                                                                                                                <li>
                                                                                                                    <div className="item">
                                                                                                                        <a
                                                                                                                            className="v2-btn-text"
                                                                                                                            onClick={(e: any) => {
                                                                                                                                e.preventDefault();
                                                                                                                                setSelectedPostId(item.id);
                                                                                                                                setIsShowFormPopup(true);
                                                                                                                                const newList = [...postList];
                                                                                                                                const post = newList.find(
                                                                                                                                    (v: any) => v.id === item.id
                                                                                                                                );

                                                                                                                                post.isShowToolTip = false;

                                                                                                                                setPostList(newList);
                                                                                                                            }}>
                                                                                                                            편집
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <div className="item">
                                                                                                                        <a
                                                                                                                            className="v2-btn-text"
                                                                                                                            onClick={(e: any) => {
                                                                                                                                e.preventDefault();
                                                                                                                                deletePostValue(item.id);
                                                                                                                            }}>
                                                                                                                            삭제
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <li>
                                                                                                                <div className="item">
                                                                                                                    <a
                                                                                                                        className="v2-btn-text"
                                                                                                                        onClick={(e: any) => {
                                                                                                                            e.preventDefault();
                                                                                                                            if (isOnNetworking) {
                                                                                                                                return;
                                                                                                                            }

                                                                                                                            const newList = [...postList];
                                                                                                                            const post = newList.find(
                                                                                                                                (v: any) => v.id === item.id
                                                                                                                            );

                                                                                                                            post.isShowToolTip = false;

                                                                                                                            setPostList(newList);
                                                                                                                            createPostReportValue(item.id);
                                                                                                                        }}>
                                                                                                                        신고
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="body" ref={(el) => (commentSectionRefs.current[index] = el)}>
                                                                                    <div className="content">
                                                                                        {item.content.split("\n").map((line: any, idx: any) => (
                                                                                            <React.Fragment key={idx}>
                                                                                                <HashtagText text={line} />
                                                                                                <br />
                                                                                            </React.Fragment>
                                                                                        ))}
                                                                                    </div>
                                                                                    <div className="comment-list">
                                                                                        <ul>
                                                                                            {item.commentList.map((comment: any, idx: number) => (
                                                                                                <li key={`post-${index}-${idx}`}>
                                                                                                    <div className="item">
                                                                                                        <div className="v2-comment">
                                                                                                            <div className="con">
                                                                                                                <div className="header">
                                                                                                                    <div className="profile">
                                                                                                                        <div className="con">
                                                                                                                            <div className="img-wrap">
                                                                                                                                <div className="v2-wannabe-thumbnail">
                                                                                                                                    <img
                                                                                                                                        src={comment.imageUrl}
                                                                                                                                        alt={comment.name}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="info-wrap">
                                                                                                                                <div className="name">
                                                                                                                                    <p>{comment.name}</p>
                                                                                                                                </div>
                                                                                                                                <div className="date">
                                                                                                                                    <p>
                                                                                                                                        {formatTimeAgo(
                                                                                                                                            comment.createdAt
                                                                                                                                        )}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className={`v2-tooltip-t1-sm ${
                                                                                                                                    comment.isShowToolTip
                                                                                                                                        ? "on"
                                                                                                                                        : ""
                                                                                                                                }`}>
                                                                                                                                <div
                                                                                                                                    className="btn-tooltip"
                                                                                                                                    onClick={(e: any) => {
                                                                                                                                        const newList = [
                                                                                                                                            ...postList,
                                                                                                                                        ];
                                                                                                                                        const post =
                                                                                                                                            newList.find(
                                                                                                                                                (v: any) =>
                                                                                                                                                    v.id ===
                                                                                                                                                    item.id
                                                                                                                                            );

                                                                                                                                        const commentValue =
                                                                                                                                            post.commentList.find(
                                                                                                                                                (v: any) =>
                                                                                                                                                    v.id ===
                                                                                                                                                    comment.id
                                                                                                                                            );

                                                                                                                                        commentValue.isShowToolTip =
                                                                                                                                            commentValue.isShowToolTip
                                                                                                                                                ? false
                                                                                                                                                : true;
                                                                                                                                        if (
                                                                                                                                            commentValue.isShowToolTip
                                                                                                                                        ) {
                                                                                                                                            newList.map(
                                                                                                                                                (v: any) => {
                                                                                                                                                    v.isShowToolTip =
                                                                                                                                                        false;

                                                                                                                                                    for (const commentValue of v.commentList) {
                                                                                                                                                        if (
                                                                                                                                                            commentValue.id !==
                                                                                                                                                            comment.id
                                                                                                                                                        ) {
                                                                                                                                                            commentValue.isShowToolTip =
                                                                                                                                                                false;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        }

                                                                                                                                        setPostList(newList);
                                                                                                                                    }}>
                                                                                                                                    <img
                                                                                                                                        src="./imgs/common/more-t1.png"
                                                                                                                                        alt="More Button"
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                <div className="tooltip-list">
                                                                                                                                    <ul>
                                                                                                                                        {comment.isOwner ? (
                                                                                                                                            <>
                                                                                                                                                <li>
                                                                                                                                                    <div className="item">
                                                                                                                                                        <a
                                                                                                                                                            className="v2-btn-text"
                                                                                                                                                            onClick={(
                                                                                                                                                                e: any
                                                                                                                                                            ) => {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                setSelectedCommentId(
                                                                                                                                                                    comment.id
                                                                                                                                                                );
                                                                                                                                                                setSelectedPostId(
                                                                                                                                                                    item.id
                                                                                                                                                                );
                                                                                                                                                                setSelectedCommentValue(
                                                                                                                                                                    comment.comment
                                                                                                                                                                );
                                                                                                                                                                setIsShowCommentPopup(
                                                                                                                                                                    true
                                                                                                                                                                );

                                                                                                                                                                const newList =
                                                                                                                                                                    [
                                                                                                                                                                        ...postList,
                                                                                                                                                                    ];
                                                                                                                                                                const post =
                                                                                                                                                                    newList.find(
                                                                                                                                                                        (
                                                                                                                                                                            v: any
                                                                                                                                                                        ) =>
                                                                                                                                                                            v.id ===
                                                                                                                                                                            item.id
                                                                                                                                                                    );

                                                                                                                                                                post.isShowToolTip =
                                                                                                                                                                    false;

                                                                                                                                                                post.commentList.map(
                                                                                                                                                                    (
                                                                                                                                                                        v: any
                                                                                                                                                                    ) =>
                                                                                                                                                                        (v.isShowToolTip =
                                                                                                                                                                            false)
                                                                                                                                                                );

                                                                                                                                                                setPostList(
                                                                                                                                                                    newList
                                                                                                                                                                );
                                                                                                                                                            }}>
                                                                                                                                                            편집
                                                                                                                                                        </a>
                                                                                                                                                    </div>
                                                                                                                                                </li>
                                                                                                                                                <li>
                                                                                                                                                    <div className="item">
                                                                                                                                                        <a
                                                                                                                                                            className="v2-btn-text"
                                                                                                                                                            onClick={(
                                                                                                                                                                e: any
                                                                                                                                                            ) => {
                                                                                                                                                                e.preventDefault();
                                                                                                                                                                deletePostCommentValue(
                                                                                                                                                                    comment.id,
                                                                                                                                                                    item.id
                                                                                                                                                                );
                                                                                                                                                            }}>
                                                                                                                                                            삭제
                                                                                                                                                        </a>
                                                                                                                                                    </div>
                                                                                                                                                </li>
                                                                                                                                            </>
                                                                                                                                        ) : (
                                                                                                                                            <li>
                                                                                                                                                <div className="item">
                                                                                                                                                    <a
                                                                                                                                                        className="v2-btn-text"
                                                                                                                                                        onClick={(
                                                                                                                                                            e: any
                                                                                                                                                        ) => {
                                                                                                                                                            e.preventDefault();
                                                                                                                                                            if (
                                                                                                                                                                isOnNetworking
                                                                                                                                                            ) {
                                                                                                                                                                return;
                                                                                                                                                            }
                                                                                                                                                            createPostCommentReportValue(
                                                                                                                                                                comment.id
                                                                                                                                                            );
                                                                                                                                                            const newList =
                                                                                                                                                                [
                                                                                                                                                                    ...postList,
                                                                                                                                                                ];
                                                                                                                                                            const post =
                                                                                                                                                                newList.find(
                                                                                                                                                                    (
                                                                                                                                                                        v: any
                                                                                                                                                                    ) =>
                                                                                                                                                                        v.id ===
                                                                                                                                                                        item.id
                                                                                                                                                                );

                                                                                                                                                            post.isShowToolTip =
                                                                                                                                                                false;

                                                                                                                                                            post.commentList.map(
                                                                                                                                                                (
                                                                                                                                                                    v: any
                                                                                                                                                                ) =>
                                                                                                                                                                    (v.isShowToolTip =
                                                                                                                                                                        false)
                                                                                                                                                            );

                                                                                                                                                            setPostList(
                                                                                                                                                                newList
                                                                                                                                                            );
                                                                                                                                                        }}>
                                                                                                                                                        신고
                                                                                                                                                    </a>
                                                                                                                                                </div>
                                                                                                                                            </li>
                                                                                                                                        )}
                                                                                                                                    </ul>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="body">
                                                                                                                    <div className="content">
                                                                                                                        {comment.isReply ? (
                                                                                                                            <>
                                                                                                                                <p className="origin">
                                                                                                                                    {comment.parentComment}
                                                                                                                                </p>
                                                                                                                                <p className="reply">
                                                                                                                                    {comment.isTranslated
                                                                                                                                        ? comment.translatedComment
                                                                                                                                        : comment.comment}
                                                                                                                                </p>
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <p>
                                                                                                                                {comment.isTranslated
                                                                                                                                    ? comment.translatedComment
                                                                                                                                    : comment.comment}
                                                                                                                            </p>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="footer">
                                                                                                                    <div
                                                                                                                        className="col"
                                                                                                                        onClick={(e: any) => {
                                                                                                                            const newList = [...postList];
                                                                                                                            const post = newList.find(
                                                                                                                                (v: any) => v.id === item.id
                                                                                                                            );
                                                                                                                            post.inputValue = "";
                                                                                                                            post.selectedCommentId = comment.id;

                                                                                                                            setPostList(newList);
                                                                                                                        }}>
                                                                                                                        <div className="v2-btn-text">
                                                                                                                            답글달기
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {comment.languageCode !==
                                                                                                                    currentLanguageCode ? (
                                                                                                                        <div className="col">
                                                                                                                            <div
                                                                                                                                className="v2-btn-text"
                                                                                                                                onClick={(e: any) => {
                                                                                                                                    if (isOnNetworking) {
                                                                                                                                        return;
                                                                                                                                    }

                                                                                                                                    translateComment(
                                                                                                                                        item.id,
                                                                                                                                        comment
                                                                                                                                    );
                                                                                                                                }}>
                                                                                                                                {comment.isTranslated
                                                                                                                                    ? "원본보기"
                                                                                                                                    : "번역하기"}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="footer">
                                                                                    <div className="posting-tool">
                                                                                        <div className="item">
                                                                                            <div className="v2-like-icon">
                                                                                                <div className="v2-btn-like">
                                                                                                    <img
                                                                                                        className="off"
                                                                                                        src="./imgs/common/icon-like-empty.png"
                                                                                                        alt="like off"
                                                                                                        style={{ display: item.isLiked ? "none" : "block" }}
                                                                                                        onClick={(e: any) => {
                                                                                                            if (isOnNetworking) {
                                                                                                                return;
                                                                                                            }
                                                                                                            createPostLikeValue(item.id);
                                                                                                        }}
                                                                                                    />
                                                                                                    <img
                                                                                                        className="on"
                                                                                                        src="./imgs/common/icon-like-fill.png"
                                                                                                        alt="like on"
                                                                                                        style={{ display: !item.isLiked ? "none" : "block" }}
                                                                                                        onClick={(e: any) => {
                                                                                                            if (isOnNetworking) {
                                                                                                                return;
                                                                                                            }
                                                                                                            deletePostLikeValue(item.id);
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="number"
                                                                                                    style={{ cursor: item.like > 0 ? "pointer" : "default" }}
                                                                                                    onClick={(e: any) => {
                                                                                                        if (!item.likeCnt) {
                                                                                                            return;
                                                                                                        }

                                                                                                        setSelectedPostId(item.id);
                                                                                                        setIsShowLikePopup(true);
                                                                                                    }}>
                                                                                                    <p>{item.likeCnt}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="item">
                                                                                            <div className="v2-comment-icon">
                                                                                                <div className="img">
                                                                                                    <img
                                                                                                        src="./imgs/common/icon-comment.png"
                                                                                                        alt="comment icon"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="number">
                                                                                                    <p>{item.commentList.length}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="comment-form">
                                                                                        <div className="v2-input v2-input-text">
                                                                                            <div className="col-input">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    placeholder={`${
                                                                                                        item.selectedCommentId ? "답글" : "댓글"
                                                                                                    }을 입력해주세요 :)`}
                                                                                                    value={item.inputValue ? item.inputValue : ""}
                                                                                                    onChange={(e: any) => {
                                                                                                        const newList = [...postList];
                                                                                                        const post = newList.find((v: any) => v.id === item.id);
                                                                                                        post.inputValue = e.target.value;
                                                                                                        setPostList(newList);
                                                                                                    }}
                                                                                                    onKeyDown={(evt) => {
                                                                                                        if (evt.key === "Enter") {
                                                                                                            if (!item.inputValue) {
                                                                                                                return;
                                                                                                            }

                                                                                                            if (isOnNetworking) {
                                                                                                                return;
                                                                                                            }
                                                                                                            createComment(item.id);
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-control">
                                                                                                <button
                                                                                                    className="btn"
                                                                                                    onClick={(e: any) => {
                                                                                                        e.preventDefault();

                                                                                                        if (!item.inputValue) {
                                                                                                            return;
                                                                                                        }

                                                                                                        if (isOnNetworking) {
                                                                                                            return;
                                                                                                        }

                                                                                                        createComment(item.id);
                                                                                                    }}>
                                                                                                    저장
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}

                                            {/* <!-- 타임라인 아이템 끝 --> */}
                                        </ul>
                                    )}

                                    {/* <!-- 타임라인 목록 끝 --> */}
                                </div>

                                <div className="control">
                                    <div
                                        className="btn-add-posting"
                                        onClick={(e: any) => {
                                            setSelectedPostId(0);
                                            setIsShowFormPopup(true);
                                        }}>
                                        <img src="./imgs/common/icon-plus-circle.png" alt="add posting" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </main>
        </>
    );
};

export default HomeComponent;
