import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";
import Pagination from "../../../commons/pagination/Pagination.container";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const WannabeInfoComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        studentInfo,
        practiceList,
        isPracticeLoading,
        limit,
        page,
        setPage,
        totalCount,
        changePracticeFavoriteStatus,
        // message
        messageRequest,
        createRequest,
        cancelRequest,
        messageBtnRef,
        // favorite
        btnRef,
        isFavorited,
        changeFavoriteStatus,
        isPublic,
    } = props;

    return (
        <main className="">
            {isPracticeLoading ? (
                <LoadingComponent />
            ) : isPublic !== null && !isPublic ? (
                <div className="private-profile">
                    <img src="./imgs/icons/lock-icon.png" alt="lock" />
                    <p>{getLanguageValue(PAGE_TYPE.AGENCY, "studentInfo.txtPrivateProfile")}</p>
                </div>
            ) : (
                <>
                    <div className="area">
                        <div className="title">
                            {/* <!-- 선택한 title에 class active 추가 --> */}
                            <button onClick={(evt) => navigate(`/agency/wannabe/info/${studentInfo?.id}`)}>정보</button>
                            <button className="active" onClick={(evt) => navigate(`/agency/wannabe/practice-video/${studentInfo?.id}`)}>
                                연습영상
                            </button>
                            <button onClick={(evt) => navigate(`/agency/wannabe/profile/${studentInfo?.id}`)}>프로필</button>
                            {studentInfo?.userSchoolId ? (
                                <button onClick={(evt) => navigate(`/agency/wannabe/school-evaluation/${studentInfo?.id}`)}>학원평가</button>
                            ) : null}
                        </div>
                        <div className="back-btn" onClick={(evt) => navigate(-1)}></div>
                    </div>
                    <div className="student-training-video">
                        <div className="wrap">
                            <div className="training-video-wrap">
                                {practiceList && practiceList.length > 0 ? (
                                    <ul className="training-video-list-wrap">
                                        {practiceList.map((item: any, idx: number) => (
                                            <li className="training-video-list" key={`practice-${idx}`}>
                                                <div className="training-video">
                                                    <div className="con">
                                                        <p className="shooting-date">{getFormattedDateValue(item.shootingDate, "YYYY.MM.DD")}</p>
                                                    </div>
                                                    <div className="video-img">
                                                        <a
                                                            onClick={(e) => {
                                                                navigate(`/agency/wannabe/practice-video-info/${item.id}`, {
                                                                    state: {
                                                                        page: page || 1,
                                                                    },
                                                                });
                                                            }}>
                                                            <div className="thumbnail">
                                                                <div className="space">
                                                                    <div
                                                                        className="like"
                                                                        onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            changePracticeFavoriteStatus(item);
                                                                        }}>
                                                                        {item.isFavorited ? (
                                                                            <img src="./imgs/icons/like-3.png" alt="number of likes" />
                                                                        ) : (
                                                                            <img src="./imgs/icons/like-2.png" alt="number of likes" />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <button className="play-btn"></button>
                                                                <canvas id={`canvas-${idx}`} style={{ display: "none" }}></canvas>
                                                                <img id={`thumbnail-${idx}`} alt="portfolio thumbnail" style={{ visibility: "hidden" }} />
                                                                <p>{item.title}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="no-filter">
                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabePracticeVideo.txtNoData")}</p>
                                    </div>
                                )}
                            </div>

                            {/* 메세지 버튼 */}
                            <div className="floating-bar">
                                <div className="wrap">
                                    <div className="chat-request-btn">
                                        {messageRequest ? (
                                            messageRequest.messageStatusCode === "messageType:accept" ? (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={() => {
                                                        messageBtnRef.current.disabled = true;
                                                        navigate(`/agency/message/${messageRequest.channelId}`, {
                                                            state: {
                                                                schoolName: studentInfo.schoolInfo?.name,
                                                            },
                                                        });
                                                        messageBtnRef.current.disabled = false;
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnGoToMessagePage")}
                                                </button>
                                            ) : messageRequest.messageStatusCode === "messageType:request" ? (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={(e) => {
                                                        cancelRequest();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnCancelRequest")}
                                                </button>
                                            ) : (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={(e) => {
                                                        createRequest();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                                </button>
                                            )
                                        ) : (
                                            <button
                                                ref={messageBtnRef}
                                                onClick={(e) => {
                                                    createRequest();
                                                }}>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                            </button>
                                        )}
                                    </div>

                                    <div className="btn-con">
                                        <button
                                            className={isFavorited ? "favorite-btn" : "favorite-disabled-btn"}
                                            ref={btnRef}
                                            onClick={(evt) => changeFavoriteStatus()}>
                                            <img src={isFavorited ? "./imgs/student/home/favorite-btn-2.png" : "./imgs/student/home/favorite-btn-1.png"} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        url={`/agency/wannabe/practice-video/${studentInfo?.id}`}
                        totalCount={totalCount}
                        currentPage={page}
                        pageSize={limit}
                        pageGroupSize={10}
                        setPage={setPage}
                    />
                </>
            )}
        </main>
    );
};

export default WannabeInfoComponent;
