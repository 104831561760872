import React, { useEffect, useRef, useState } from "react";
import PracticeNewComponent from "./PracticeNew.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

import * as ProfileApi from "api/student/Profile.api";
import * as DataApi from "api/common/Data.api";
import * as VideoHelper from "helpers/Video.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getCurrentDate } from "helpers/Datetime.helper";

const PracticeNewContainer = (props: any) => {
  const location = useLocation();
  const btnRef = useRef<any>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOnNetworking, setIsOnNetworking] = useState(false);
  const videoRef = useRef(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const { id } = useParams();

  const [practiceParams, setPracticeParams] = useState({
    title: "",
    content: "",
    videoId: "",
    videoName: "",
    shootingDate: getCurrentDate(),
    visibilityCode: "visibility:public",
    url: "",
  });

  const getPracticeVideoInfo = async () => {
    const result = await ProfileApi.getInfoPractice(+id);

    if (result?.info) {
      if (result.info.videoInfo) {
        const videoInfo = await DataApi.getVideoPresignedUrl(
          result?.info?.videoInfo?.id
        );
        if (videoInfo) {
          result.info.videoInfo.url = videoInfo.result;
        }
      }
      setPracticeParams({
        title: result.info.title || "",
        content: result.info.content || "",
        videoId: result.info.videoInfo?.id || "",
        videoName: result.info.videoInfo?.name || "",
        shootingDate: result.info.shootingDate || "",
        visibilityCode: result.info.visibilityCode || "visibility:public",
        url: result.info.videoInfo.url || "",
      });

      setSelectedVideoUrl(result.info.videoInfo.url);
    } else {
      navigate("/student/profile", {
        state: {
          tab: location?.state?.tab || "practice",
          profileSubTab: location?.state?.profileSubTab || "main",
          page: 1,
        },
      });
    }
  };

  const onChangeInput = (componentName: string, value: any) => {
    // 계정 정보
    if (componentName === "title") {
      setPracticeParams({ ...practiceParams, title: value });
    } else if (componentName === "content") {
      setPracticeParams({ ...practiceParams, content: value });
    } else if (componentName === "shootingDate") {
      setPracticeParams({ ...practiceParams, shootingDate: value });
    } else if (componentName === "visibilityCode") {
      setPracticeParams({ ...practiceParams, visibilityCode: value });
    }
  };

  const uploadVideo = async (evt: any) => {
    setIsOnNetworking(true);
    setIsVideoLoading(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const videoInfo = await VideoHelper.getVideoInfo(file);
    if (!videoInfo) {
      setIsVideoLoading(false);
      alert("Please choose video file");
      return false;
    }
    const result = await DataApi.uploadVideo(file, false);
    practiceParams.videoId = result.id;

    setPracticeParams({ ...practiceParams, videoName: videoInfo.name });
    setPracticeParams({ ...practiceParams, videoId: practiceParams.videoId });

    const uploadVideoInfo = await DataApi.getVideoPresignedUrl(
      practiceParams.videoId
    );

    if (uploadVideoInfo) {
      practiceParams.url = uploadVideoInfo.result;
      setSelectedVideoUrl(practiceParams.url);
    }

    setIsOnNetworking(false);
    setIsVideoLoading(false);
  };
  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  const handleEnded = () => {
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
  };

  const handlePlay = () => {
    setInitFlag(false);
  };

  const handlePause = () => {
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
    setInitFlag(true);
  };

  const isDisabled = async () => {
    if (!practiceParams.title) {
      alert(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profilePracticeNew.errPleaseEnterTitle"
        )
      );
      return true;
    } else if (!practiceParams?.videoId) {
      alert(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profilePracticeNew.errPleaseSelectVideo"
        )
      );
      return true;
    } else if (!practiceParams.shootingDate) {
      alert(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profilePracticeNew.errPleaseSelectVideoShootingDate"
        )
      );
      return true;
    } else if (!practiceParams.content) {
      alert(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profilePracticeNew.errPleaseEnterContent"
        )
      );
      return true;
    } else if (!practiceParams.visibilityCode) {
      alert(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profilePracticeNew.errPleaseSelectVisibility"
        )
      );
      return true;
    } else {
      return false;
    }
  };

  const onClickBtnSave = async () => {
    if (!(await isDisabled())) {
      btnRef.current.disabled = true;

      const accountResult = await ProfileApi.createPractice(practiceParams);
      if (accountResult) {
        btnRef.current.disabled = false;

        navigate("/student/profile", {
          state: {
            tab: location?.state?.tab || "practice",
            profileSubTab: location?.state?.profileSubTab || "main",
            page: 1,
          },
        });
      }

      btnRef.current.disabled = false;
    }
  };

  const onClickBtnUpdate = async () => {
    if (!(await isDisabled())) {
      btnRef.current.disabled = true;

      const accountResult = await ProfileApi.updatePracticeVideo(
        +id,
        practiceParams
      );
      if (accountResult) {
        btnRef.current.disabled = false;

        navigate(`/student/profile/practice/${id}`, {
          state: {
            tab: location?.state?.tab || "practice",
            profileSubTab: location?.state?.profileSubTab || "main",
            page: location?.state?.page || 1,
          },
        });
      }

      btnRef.current.disabled = false;
    }
  };

  const init = async () => {
    setIsLoading(true);
    await getPracticeVideoInfo();
    setIsLoading(false);
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
        video.style.visibility = "visible";
        video.play().catch((e: any) => console.log(e));
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [selectedVideoUrl]);

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(practiceParams?.videoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(practiceParams?.videoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  useEffect(() => {
    if (id) {
      init();
    }
  }, [id]);

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <PracticeNewComponent
      {...props}
      id={id}
      practiceParams={practiceParams}
      onChangeInput={onChangeInput}
      uploadVideo={uploadVideo}
      videoRef={videoRef}
      onClickBtnSave={onClickBtnSave}
      onClickBtnUpdate={onClickBtnUpdate}
      btnRef={btnRef}
      isOnNetworking={isOnNetworking}
      isVideoLoading={isVideoLoading}
    />
  );
};

export default PracticeNewContainer;
