import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUpdatedFavoriteCount } from "reducers/Agency";

import { makeRank } from "helpers/Score.helper";

import * as AgencyApi from "api/student/Student.api";
import * as MessageApi from "api/message/Message.api";
import * as ProfileApi from "api/agency/Profile.api";
import * as DataApi from "api/common/Data.api";

import WannabeProfileComponent from "./WannabeProfile.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const WannabeProfileContainer = (props: any) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [studentInfo, setStudentInfo] = useState<any>();
    const [isPublic, setIsPublic] = useState(null);

    // message
    const [messageRequest, setMessageRequest] = useState<any>({});
    const messageBtnRef = useRef<any>();
    const [isLoading, setIsLoading] = useState(true);
    //
    const [profile, setProfile] = useState(null);

    //
    const videoRef = useRef(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [initFlag, setInitFlag] = useState(false);

    //
    const sliderRef = useRef<any>(null);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [popupList, setPopupList] = useState([]);
    const [categoryCode, setCategoryCode] = useState("");
    const [typeCode, setTypeCode] = useState("");

    const [hasVerifiedEmail, setHasVerifiedEmail] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);

    const [isVideoPlayed, setIsVideoPlayed] = useState(false);

    const getInfo = async () => {
        const result = await AgencyApi.getInfoForAgency(+id);

        if (result?.info?.id) {
            setStudentInfo(result.info);
            setIsFavorited(result.info.isFavorited);
        } else {
            setIsPublic(result.isActive);
        }
    };

    const getProfile = async () => {
        const result = await ProfileApi.getInfoForAgency({
            userStudentId: +id,
        });
        if (result?.info) {
            if (result?.info?.hasVerifiedEmail) {
                setHasVerifiedEmail(true);
            }

            if (result.info.introVideoInfo?.videoId) {
                const videoInfo = await DataApi.getVideoPresignedUrl(result.info.introVideoInfo.videoId);
                if (videoInfo) {
                    result.info.introVideoInfo.url = videoInfo.result;
                }
                // await ProfileApi.updateViewCount({
                //     profileMediaId: result.info.introVideoInfo.id,
                //     profileMediaType: "introVideo",
                // });z
            }

            if (result.info.faceImageList) {
                const frontImage = result.info.faceImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:front"
                );

                const backImage = result.info.faceImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:back"
                );

                const leftImage = result.info.faceImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:left"
                );

                const rightImage = result.info.faceImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:right"
                );
                result.info.faceImageInfo = {};
                result.info.faceImageInfo.front = frontImage;
                result.info.faceImageInfo.back = backImage;
                result.info.faceImageInfo.left = leftImage;
                result.info.faceImageInfo.right = rightImage;

                if (frontImage.id) {
                    const frontInfo = await DataApi.getImagePresignedUrl(result.info.faceImageInfo.front.imageId);
                    
                    if (frontInfo) {
                        result.info.faceImageInfo.front.url = frontInfo.result;
                    }
                }

                if (backImage.id) {
                    const backInfo = await DataApi.getImagePresignedUrl(result.info.faceImageInfo.back.imageId);
                    if (backInfo) {
                        result.info.faceImageInfo.back.url = backInfo.result;
                    }
                }

                if (leftImage.id) {
                    const leftInfo = await DataApi.getImagePresignedUrl(result.info.faceImageInfo.left.imageId);
                    if (leftInfo) {
                        result.info.faceImageInfo.left.url = leftInfo.result;
                    }
                }

                if (rightImage.id) {
                    const rightInfo = await DataApi.getImagePresignedUrl(result.info.faceImageInfo.right.imageId);
                    if (rightInfo) {
                        result.info.faceImageInfo.right.url = rightInfo.result;
                    }
                }
            }

            if (result.info.fullBodyImageList) {
                const frontImage = result.info.fullBodyImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:front"
                );

                const backImage = result.info.fullBodyImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:back"
                );

                const leftImage = result.info.fullBodyImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:left"
                );

                const rightImage = result.info.fullBodyImageList.find(
                    (image: { profileImageTypeCode: string }) => image.profileImageTypeCode === "profileImageType:right"
                );
                result.info.fullBodyImageInfo = {};
                result.info.fullBodyImageInfo.front = frontImage;
                result.info.fullBodyImageInfo.back = backImage;
                result.info.fullBodyImageInfo.left = leftImage;
                result.info.fullBodyImageInfo.right = rightImage;

                if (frontImage.id) {
                    const frontInfo = await DataApi.getImagePresignedUrl(result.info.fullBodyImageInfo.front.imageId);
                    if (frontInfo) {
                        result.info.fullBodyImageInfo.front.url = frontInfo.result;
                    }
                }

                if (backImage.id) {
                    const backInfo = await DataApi.getImagePresignedUrl(result.info.fullBodyImageInfo.back.imageId);
                    if (backInfo) {
                        result.info.fullBodyImageInfo.back.url = backInfo.result;
                    }
                }

                if (leftImage.id) {
                    const leftInfo = await DataApi.getImagePresignedUrl(result.info.fullBodyImageInfo.left.imageId);
                    if (leftInfo) {
                        result.info.fullBodyImageInfo.left.url = leftInfo.result;
                    }
                }

                if (rightImage.id) {
                    const rightInfo = await DataApi.getImagePresignedUrl(result.info.fullBodyImageInfo.right.imageId);
                    if (rightInfo) {
                        result.info.fullBodyImageInfo.right.url = rightInfo.result;
                    }
                }
            }

            setProfile(result.info);
            if (result.info.introVideoInfo) {
                setSelectedVideoUrl(result.info.introVideoInfo.url);
            }
            if (result.info.isProfileActive !== "undefined") {
                setIsPublic(result.info.isProfileActive);
            }
        }
    };

    // message
    const getMessageRequest = async () => {
        const requestInfo = await MessageApi.getRequestMessage(+id);

        if (requestInfo?.info) {
            setMessageRequest(requestInfo.info);
        }
    };

    const createRequest = async () => {
        messageBtnRef.current.disabled = true;
        const result = await MessageApi.createRequest({ studentId: id });
        if (result) {
            await getMessageRequest();
        }
        messageBtnRef.current.disabled = false;
    };

    const cancelRequest = async () => {
        messageBtnRef.current.disabled = true;
        if (messageRequest) {
            const result = await MessageApi.updateMessageInfo(messageRequest?.id, {
                messageType: "messageType:cancel",
            });
            if (result) {
                await getMessageRequest();
            }
        }
        messageBtnRef.current.disabled = false;
    };

    const createRequestProfile = async (mediaType: string, categoryCode: string, typeCode: string) => {
        const result: any = await ProfileApi.createRequestProfile({
            userStudentId: id,
            profileMediaType: mediaType,
            profileImageCategoryCode: `profileImageCategory:${categoryCode}`,
            profileImageTypeCode: `profileImageType:${typeCode}`,
        });
        if (result.status === 200) {
            alert(getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtReqeustCompleted"));
        }
    };

    const openViewPopup = async (mediaId: string, mediaType: string, categoryCode: string, typeCode: string) => {
        const result = await ProfileApi.getListProfileForAgency({
            userStudentId: id,
            profileImageCategoryCode: `profileImageCategory:${categoryCode}`,
            profileImageTypeCode: `profileImageType:${typeCode}`,
        });
        if (result.list) {
            for (const item of result.list) {
                const urlResult = await DataApi.getImagePresignedUrl(item.imageId);
                if (urlResult) {
                    item.url = urlResult.result;
                }
                item.isViewCountUpdated = false;
            }

            if (result.list.length > 0) {
                result.list[0].isViewCountUpdated = true;
            }

            setPopupList(result.list);
        }
        setCategoryCode(categoryCode);
        setTypeCode(typeCode);
        setIsShowPopup(true);

        await updateMediaViewCount(mediaId, mediaType);
    };

    const updateMediaViewCount = async (profileMediaId: any, profileMediaType: any) => {
        await ProfileApi.updateViewCount({
            profileMediaId,
            profileMediaType,
        });
    };

    const getVideoUrl = async (id: string) => {
        if (id) {
            const videoInfo = await DataApi.getVideoPresignedUrl(id);
            if (videoInfo) {
                const url = videoInfo.result;
                return url;
            }
        }
    };

    const handleEnded = () => {
        console.log("ended");
        const video = videoRef.current;
        video.isEnded = true;
        setCurrentTime(0);
    };

    const handleSeeked = () => {
        console.log("seek");
        const video = videoRef.current;
        if (video) {
            setCurrentTime(video.currentTime);
            // video.play();
        }
    };

    const handlePlay = async () => {
        console.log("play");
        setInitFlag(false);
        const video = videoRef.current;

        if (video.isEnded || !isVideoPlayed) {
            await ProfileApi.updateViewCount({
                profileMediaId: profile.introVideoInfo.id,
                profileMediaType: "introVideo",
            });
        }

        setIsVideoPlayed(true);

        video.isEnded = false;
    };

    const handlePause = () => {
        console.log("pause");
        const video = videoRef.current;
        if (video) {
            setCurrentTime(video.currentTime);
        }
        setInitFlag(true);
    };

    useEffect(() => {
        const handleInitFlagChange = async () => {
            if (!initFlag) {
                const url = await getVideoUrl(profile?.introVideoInfo?.videoId);
                setSelectedVideoUrl(url);
                setInitFlag(false);
            }
        };

        handleInitFlagChange();
    }, [initFlag]);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            video.src = selectedVideoUrl;
            video.muted = true;
            video.playsInline = true;
            video.currentTime = currentTime;
            // video.isEnded = true;
            video.load();
            const handleLoadedData = () => {
                // video.play().catch((e: any) => console.log(e));
            };

            video.addEventListener("loadeddata", handleLoadedData);
            video.addEventListener("play", handlePlay);
            video.addEventListener("pause", handlePause);
            video.addEventListener("ended", handleEnded);
            video.addEventListener("seeked", handleSeeked);

            return () => {
                video.removeEventListener("loadeddata", handleLoadedData);
                video.removeEventListener("play", handlePlay);
                video.removeEventListener("pause", handlePause);
                video.removeEventListener("ended", handleEnded);
                video.removeEventListener("seeked", handleSeeked);
            };
        }
    }, [selectedVideoUrl]);

    const init = async () => {
        await getInfo();
        await getProfile();
        setIsLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        //if (studentInfo?.userSchoolId) {
        getMessageRequest();
        // }
    }, [studentInfo?.id]);

    return (
        <WannabeProfileComponent
            studentInfo={studentInfo}
            isPublic={isPublic}
            // message
            messageRequest={messageRequest}
            createRequest={createRequest}
            cancelRequest={cancelRequest}
            isLoading={isLoading}
            messageBtnRef={messageBtnRef}
            //
            profile={profile}
            videoRef={videoRef}
            selectedVideoUrl={selectedVideoUrl}
            createRequestProfile={createRequestProfile}
            //
            isShowPopup={isShowPopup}
            setIsShowPopup={setIsShowPopup}
            popupList={popupList}
            setPopupList={setPopupList}
            openViewPopup={openViewPopup}
            categoryCode={categoryCode}
            typeCode={typeCode}
            sliderRef={sliderRef}
            hasVerifiedEmail={hasVerifiedEmail}
            updateMediaViewCount={updateMediaViewCount}
            isFavorited={isFavorited}
        />
    );
};

export default WannabeProfileContainer;
