import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as AgencyApi from "api/agency/Agency.api";
import * as DataApi from "api/common/Data.api";
import * as AppApi from "api/App.api";
import * as AuthApi from "api/common/Auth.api";

import MyComponent from "./My.component";
import UpdateMy from "./UpdateMy.container";
import { removeAuthTokens } from "helpers/Auth.helper";
import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import { removePushToken } from "helpers/Firebase.helper";

const MyContainer = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info: myInfo } = useSelector((state: any) => state.user);

  const [agencyInfo, setAgencyInfo] = useState<any>({});

  const [marketingTermList, setMarketingTermList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [termId, setTermId] = useState(0);

  const type = location.pathname?.split("-")[1] || "";
  const [updateType, setUpdateType] = useState(type);

  const getInfo = async () => {
    const result = await AgencyApi.getInfo(myInfo.userAgencyId);

    if (result?.info) {
      setAgencyInfo(result.info);

      const termList = result.info.termList;

      if (!termId) {
        termList.map((item: any) => (item.disabled = false));
        setMarketingTermList(termList);
      } else {
        setMarketingTermList((prev: any) => {
          const list = [...prev];
          list.find((i: any) => i.id === termId).disabled = false;

          return list;
        });

        setTermId(0);
      }
    }
  };

  const getTermList = async () => {
    const accountType = "agency";
    const result = await AuthApi.getTermListByType({ accountType });

    if (result?.list) {
      setTermList(result.list.slice(0, 2));
    }
  };

  const downloadFile = () => {
    DataApi.fileDownload(agencyInfo.businessRegistrationFileInfo.url);
  };

  const updateUserTerm = async () => {
    const { hasAgreed } = marketingTermList.find((i: any) => i.id === termId);
    const result: any = await AppApi.updateUserTerm(termId, {
      hasAgreed,
    });

    if (result) {
      getInfo();
    }
  };

  const updateMarketingTermList = (idx: number, checked: boolean) => {
    setMarketingTermList((prev: any) => {
      const list = [...prev];
      list[idx].hasAgreed = checked;
      list[idx].disabled = true;

      return list;
    });
  };

  const sendVerifyEmail = async () => {
    const params = {
      username: myInfo.username,
      accountTypeCode: "accountType:agency",
    };
    const result: any = await AuthApi.sendVerifyEmail(params);

    if (result.status === 200) {
      alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.sendEmail"));
    }
  };

  const logout = async () => {
    await removePushToken();
    removeAuthTokens();
    dispatch(setIsLogin(false));
    dispatch(setUserInfo({}));
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (termId) {
      updateUserTerm();
    }
  }, [marketingTermList]);

  useEffect(() => {
    if (location.pathname === "/agency/my") {
      setUpdateType("");
    } else if (location.pathname.includes("/agency/my/")) {
      const updateType = location.pathname?.split("-")[1] || "";
      setUpdateType(updateType);
    }
  }, [location]);

  useEffect(() => {
    getTermList();
    getInfo();
  }, []);

  return !updateType ? (
    <MyComponent
      email={myInfo.username}
      agencyInfo={agencyInfo}
      setTermId={setTermId}
      marketingTermList={marketingTermList}
      termList={termList}
      setUpdateType={setUpdateType}
      onClickBtnDownloadFile={downloadFile}
      updateMarketingTermList={updateMarketingTermList}
      sendVerifyEmail={sendVerifyEmail}
      logout={logout}
    />
  ) : (
    <UpdateMy
      getInfo={getInfo}
      agencyInfo={agencyInfo}
      setAgencyInfo={setAgencyInfo}
      updateType={updateType}
      setUpdateType={setUpdateType}
    />
  );
};

export default MyContainer;
