import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WannabePracticeVideoInfoComponent from "./WannabePracticeVideoInfo.component";
import * as AgencyApi from "api/student/Student.api";
import * as ProfileApi from "api/agency/Profile.api";
import * as DataApi from "api/common/Data.api";
import * as MessageApi from "api/message/Message.api";
import * as StudentApi from "api/student/Student.api";

const WannabePracticeVideoInfoContainer = (props: any) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [practiceVideoInfo, setPracticeVideoInfo] = useState(null);
    const [studentInfo, setStudentInfo] = useState<any>();
    const practiceBtnRef = useRef<any>();

    const detailVideoRef = useRef(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [currentTime, setCurrentTime] = useState(0);
    const [initFlag, setInitFlag] = useState(false);

    const [messageRequest, setMessageRequest] = useState<any>({});
    const messageBtnRef = useRef<any>();

    // favorite
    const btnRef = useRef<any>();
    const [isFavorited, setIsFavorited] = useState(false);
    const [isPublic, setIsPublic] = useState(null);

    const getPracticeVideoInfo = async () => {
        const result = await ProfileApi.getInfoPractice(+id);

        if (result?.info) {
            const studentResult = await AgencyApi.getInfoForAgency(parseInt(result.info.userStudentId));

            if (studentResult?.info?.id) {
                setStudentInfo(studentResult.info);
                setIsFavorited(studentResult.info.isFavorited);
            } else {
                setIsPublic(result.isActive);
            }

            if (result.info.videoId) {
                const videoInfo = await DataApi.getVideoPresignedUrl(result?.info?.videoId);
                if (videoInfo) {
                    result.info.url = videoInfo.result;
                }
            }
            setPracticeVideoInfo(result.info);
            setSelectedVideoUrl(result.info.url);
        } else {
            navigate(-1);
        }
    };

    const changePracticeFavoriteStatus = async () => {
        practiceBtnRef.current.disabled = true;

        const params = { profilePracticeVideoId: practiceVideoInfo.id };
        const result = practiceVideoInfo.isFavorited ? await ProfileApi.deletePracticeFavorite(params) : await ProfileApi.createPracticeFavorite(params);

        if (result) {
            practiceVideoInfo.isFavorited
                ? setPracticeVideoInfo((prevState: any) => ({
                      ...prevState,
                      isFavorited: false,
                  }))
                : setPracticeVideoInfo((prevState: any) => ({
                      ...prevState,
                      isFavorited: true,
                  }));
            practiceBtnRef.current.disabled = false;
        } else {
            practiceBtnRef.current.disabled = true;
        }
    };

    const getMessageRequest = async () => {
        const requestInfo = await MessageApi.getRequestMessage(studentInfo.id);
        console.log(requestInfo);
        if (requestInfo?.info) {
            setMessageRequest(requestInfo.info);
        }

        setIsLoading(false);
    };

    const cancelRequest = async () => {
        messageBtnRef.current.disabled = true;
        if (messageRequest) {
            const result = await MessageApi.updateMessageInfo(messageRequest?.id, {
                messageType: "messageType:cancel",
            });
            if (result) {
                await getMessageRequest();
            }
        }
        messageBtnRef.current.disabled = false;
    };

    const createRequest = async () => {
        messageBtnRef.current.disabled = true;
        const result = await MessageApi.createRequest({ studentId: studentInfo.id });
        if (result) {
            await getMessageRequest();
        }
        messageBtnRef.current.disabled = false;
    };

    const changeFavoriteStatus = async () => {
        btnRef.current.disabled = true;

        const params = { studentId: studentInfo.id };
        const result = isFavorited ? await StudentApi.deleteFavorite(params) : await StudentApi.createFavorite(params);

        if (result) {
            isFavorited ? setIsFavorited(false) : setIsFavorited(true);
            btnRef.current.disabled = false;
        } else {
            btnRef.current.disabled = true;
        }
    };

    const practiceDetailInit = async () => {
        setIsLoading(true);
        await getPracticeVideoInfo();
        setIsLoading(false);
    };

    const getVideoUrl = async (id: string) => {
        if (id) {
            const videoInfo = await DataApi.getVideoPresignedUrl(id);
            if (videoInfo) {
                const url = videoInfo.result;
                return url;
            }
        }
    };

    const handleEnded = () => {
        setCurrentTime(0);
    };

    const handleSeeked = () => {
        const video = detailVideoRef.current;
        if (video) {
            setCurrentTime(video.currentTime);
        }
    };

    const handlePlay = () => {
        setInitFlag(false);
    };

    const handlePause = () => {
        const video = detailVideoRef.current;
        if (video) {
            setCurrentTime(video.currentTime);
        }
        setInitFlag(true);
    };

    useEffect(() => {
        const handleInitFlagChange = async () => {
            if (!initFlag) {
                console.log(practiceVideoInfo);
                const url = await getVideoUrl(practiceVideoInfo?.videoId);
                setSelectedVideoUrl(url);
                setInitFlag(false);
            }
        };

        handleInitFlagChange();
    }, [initFlag]);

    
    useEffect(() => {
        //if (studentInfo?.userSchoolId) {
        
        if (studentInfo) {
            getMessageRequest();
        }
         
        // }
      }, [studentInfo]);

    useEffect(() => {
        const video = detailVideoRef.current;

        if (video) {
            video.src = selectedVideoUrl;
            video.muted = true;
            video.playsInline = true;
            video.currentTime = currentTime;
            video.load();
            const handleLoadedData = () => {
                // video.play().catch((e: any) => console.log(e));
            };

            video.addEventListener("loadeddata", handleLoadedData);
            video.addEventListener("play", handlePlay);
            video.addEventListener("pause", handlePause);
            video.addEventListener("ended", handleEnded);
            video.addEventListener("seeked", handleSeeked);

            return () => {
                video.removeEventListener("loadeddata", handleLoadedData);
                video.removeEventListener("play", handlePlay);
                video.removeEventListener("pause", handlePause);
                video.removeEventListener("ended", handleEnded);
                video.removeEventListener("seeked", handleSeeked);
            };
        }
    }, [selectedVideoUrl]);

    useEffect(() => {
        practiceDetailInit();
    }, []);

    return (
        <WannabePracticeVideoInfoComponent
            isLoading={isLoading}
            studentInfo={studentInfo}
            practiceVideoInfo={practiceVideoInfo}
            detailVideoRef={detailVideoRef}
            practiceBtnRef={practiceBtnRef}
            changePracticeFavoriteStatus={changePracticeFavoriteStatus}
            // message
            messageRequest={messageRequest}
            createRequest={createRequest}
            cancelRequest={cancelRequest}
            messageBtnRef={messageBtnRef}
            // favorite
            btnRef={btnRef}
            isFavorited={isFavorited}
            changeFavoriteStatus={changeFavoriteStatus}
            isPublic={isPublic}
        />
    );
};

export default WannabePracticeVideoInfoContainer;
