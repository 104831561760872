import React, { useState } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as AuthApi from "api/common/Auth.api";

import CheckPasswordComponent from "./CheckPassword.component";

const ContactContainer = (props: any) => {
  const { setIsCheckedPassword } = props;
  const [password, setPassword] = useState("");

  const checkPassword = async () => {
    const result = await AuthApi.checkPassword({ password });

    if (result) {
      setIsCheckedPassword(true);
    } else {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "checkPassword.errPasswordMatch")
      );
    }
  };

  return (
    <CheckPasswordComponent
      password={password}
      setPassword={setPassword}
      onClickBtnCheckPassword={checkPassword}
    />
  );
};

export default ContactContainer;
