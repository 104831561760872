import React, { useEffect, useState } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getFormattedDateValue, addDate, formatTimeAgo } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { createFollow, deleteFollow, getPostLikeList } from "api/Post.api";

const LikePopupComponent = (props: any) => {
    const { isShowLikePopup, setIsShowLikePopup, postId, setSelectedPostId } = props;

    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const getLikeList = async (needLoading: boolean) => {
        if (needLoading) {
            setIsLoading(true);
        }
        const reulst = await getPostLikeList(postId);

        if (reulst?.list) {
            setList(reulst?.list);
        }
        if (needLoading) {
            setIsLoading(false);
        }
    };

    const createFlowValue = async (followedUserId: string) => {
        setIsOnNetworking(true);
        const result: any = await createFollow({ followedUserId });
        if (result?.status === 200) {
            getLikeList(false);
        }
        setIsOnNetworking(false);
    };

    const deleteFlowValue = async (followedUserId: string) => {
        setIsOnNetworking(true);
        const result: any = await deleteFollow({ followedUserId });
        if (result?.status === 200) {
            getLikeList(false);
        }
        setIsOnNetworking(false);
    };

    useEffect(() => {
        if (!isShowLikePopup) {
            setList([]);
            setSelectedPostId(0);
        } else {
            getLikeList(true);
        }
    }, [isShowLikePopup]);

    return (
        <div className="v2-popup v2-popup-like" style={{ display: isShowLikePopup ? "block" : "none" }}>
            <div className="wrap">
                <div className="con">
                    <div className="header">
                        <div className="title">
                            <h6>
                                <strong>좋아요</strong> 목록
                            </h6>
                        </div>
                        <div className="btn-close-popup" onClick={(e: any) => setIsShowLikePopup(false)}>
                            <img src="./imgs/common/icon-close-popup.png" alt="close popup button" />
                        </div>
                    </div>
                    <div className="body">
                        {isLoading ? (
                            <LoadingComponent></LoadingComponent>
                        ) : (
                            <div className="v2-wannabe-list">
                                <ul>
                                    {list.map((item: any, number) => (
                                        <li key={`post-like-${number}`}>
                                            <div className="item">
                                                <div className="v2-wannabe-follow-item">
                                                    <div className="con">
                                                        <div className="col col-profile">
                                                            <div className="profile">
                                                                <div className="con">
                                                                    <div className="col-thumbnail">
                                                                        <div className="v2-wannabe-thumbnail">
                                                                            <img src={item.imageUrl} alt={item.name} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-info">
                                                                        <div className="name">
                                                                            <p>{item.isOwner ? "나" : item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!item.isOwner ? (
                                                            <>
                                                                {item.relationship === 0 ? (
                                                                    <div className="col col-control">
                                                                        <button
                                                                            className="v2-btn-toggle-t1 on"
                                                                            disabled={isOnNetworking}
                                                                            onClick={(e) => {
                                                                                createFlowValue(item.creatorUserId);
                                                                            }}>
                                                                            팔로우
                                                                        </button>
                                                                    </div>
                                                                ) : item.relationship === 1 ? (
                                                                    <div className="col col-control">
                                                                        <button
                                                                            className="v2-btn-toggle-t1 on"
                                                                            disabled={isOnNetworking}
                                                                            onClick={(e) => {
                                                                                createFlowValue(item.creatorUserId);
                                                                            }}>
                                                                            맞 팔로우
                                                                        </button>
                                                                    </div>
                                                                ) : item.relationship === 2 || item.relationship === 3 ? (
                                                                    <div className="col col-control">
                                                                        <button
                                                                            disabled={isOnNetworking}
                                                                            className="v2-btn-toggle-t1"
                                                                            onClick={(e) => {
                                                                                deleteFlowValue(item.creatorUserId);
                                                                            }}>
                                                                            팔로우 취소
                                                                        </button>
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="footer"></div>
                </div>
            </div>
        </div>
    );
};

export default LikePopupComponent;
